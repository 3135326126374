<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <section>
          <div class="position-relative">
            <div class="custom-filter-dashboad mr-2">
              <v-row>
                <v-col cols="6">
                  <v-btn color="primary">
                    <span
                      class="text-h5 pr-3 pl-3"
                      @click="handleExportFile()"
                    >
                      {{ $t('messages.export_csv') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="date"
                      locale="ja-jn"
                      no-title
                      scrollable
                      type="month"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        {{ $t('messages.cancel') }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        {{ $t('messages.ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
            <vue-highcharts
              style="height: 100%; width: 100%;"
              :highcharts="Highcharts"
              :options="optionsCustomer"
            />
          </div>
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import moment from 'moment'
  import VueHighcharts from 'vue2-highcharts'
  import Highcharts from 'highcharts'
  import { extend } from 'vee-validate'
  import { required, email } from 'vee-validate/dist/rules'
  import XLSX from 'xlsx'

  export default {
    name: 'DashboardView',
    components: {
      VueHighcharts,
    },
    data () {
      return {
        Highcharts: Highcharts,
        totalPlanneds: [],
        totalActuals: [],
        categories: [],
        yAxis1: ['0', '10', '20', '30', '40', '50'],
        yAxis2: ['0', '30', '60', '90', '120', '150'],
        moment,
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        info: {},
        itemsyear: [this.$t('chart.recently')],
        itemsyear2: [this.$t('chart.recently')],
        optionChart2: '2021年',
        optionChart: this.$t('chart.recently'),
        totalEmployee: 0,
        totalJobsPartimer: 0,
        totalCustomer: 0,
        totalJob: 20,
        items: [],
        editedEmail: {
          emails: [{ name: '', email: '' }],
        },
        mailsRemoved: [],
        requestDialog: false,
        userType: 0,
        dialogDelete: false,
        itemsSelecteCustomer: [
          '最近',
          '2021年',
          '2020年',
          '2019年',
        ],
        optionsCustomer: {},
        date: new Date().toISOString().substr(0, 7),
        menu: false,
        ws_data: [],
        ws_data2: [],
      }
    },
    computed: {
      ...get('employee', ['message', 'status', 'error', 'dataDashboadFulltime']),
    },
    watch: {
      dataDashboadFulltime (value) {
        const workTimeForDateActuals = []
        let totalAllPlanned = 0
        let totalAllActual = 0
        let totalDiff = 0
        let totalRate = 0
        const totalIndex = value.customers.length

        const totalPlanneds = []
        const totalActuals = []
        const categories = []
        const wsData = [
          ['工場数', '総計', '注文', '出勤', '欠勤', '率'],
          [0, '', 0, 0, 0, 0],
          ['No', '工場名', '注文', '出勤', '欠勤', '率'],
        ]

        const ws2Data = [
          ['No', '工場名', '注文名数', '出勤名数', '達成率', '日付', '１日', '２日', '３日', '４日', '５日', '６日', '７日', '８日', '９日', '１０日', '１１日', '１２日', '１３日', '１４日', '１５日', '１６日', '１７日', '１８日', '１９日', '２０日', '２１日', '２２日', '２３日', '２４日', '２５日', '２６日', '２７日', '２８日', '２９日', '３０日', '31日'],
          ['', '', '', '', '', '曜日', '水', '木', '金', '土', '日', '月', '火', '水', '木', '金', '土', '日', '月', '火', '水', '木', '金', '土', '日', '月', '火', '水', '木', '金', '土', '日', '月', '火', '水', '木', '金'],
        ]
        value.customers.forEach((customer, index) => {
          const totalPlannedFlowDates = [
            index + 1,
            customer.name,
            customer.totalPlanned,
            customer.totalActual,
            customer.rate,
            '注文',
          ]
          const totalActualFlowDates = [
            '',
            '',
            '',
            '',
            '',
            '出勤',
          ]
          const totalDiffFlowDates = [
            '',
            '',
            '',
            '',
            '',
            '欠勤',
          ]
          const actualFlowDates = []

          totalPlanneds.push(customer.totalPlanned)
          totalActuals.push(customer.totalActual)
          categories.push(customer.name)
          wsData.push([
            index + 1,
            customer.name,
            customer.totalPlanned,
            customer.totalActual,
            customer.diff,
            customer.rate,
          ])
          totalAllPlanned += customer.totalPlanned
          totalAllActual += customer.totalActual
          totalDiff += customer.diff
          customer.workTimeForDate.forEach(workTime => {
            totalPlannedFlowDates.push(workTime.totalPlanned)
            totalActualFlowDates.push(workTime.totalActual)
            totalDiffFlowDates.push(workTime.diff)
            actualFlowDates.push(workTime.totalActual)
          })

          ws2Data.push(totalPlannedFlowDates)
          workTimeForDateActuals.push(actualFlowDates)
          ws2Data.push(totalActualFlowDates)
          ws2Data.push(totalDiffFlowDates)
        })
        totalRate = (totalAllActual / totalAllPlanned) ? ((totalAllActual / totalAllPlanned) * 100).toFixed(2) : 0
        wsData[1] = [
          totalIndex,
          '',
          totalAllPlanned,
          totalAllActual,
          totalDiff,
          totalRate,
        ]
        this.ws_data = wsData
        this.ws_data2 = ws2Data
        this.totalPlanneds = totalPlanneds
        this.totalActuals = totalActuals
        this.categories = categories
        this.getChartProperties()
      },
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      message (value) {
        if (value === 'success') {
          this.showSnackBar('messages.success')
        } else if (value === 'updateMailSuccess') {
          this.showSnackBar('messages.updateMailSuccess')
        } else if (value === 'cancelSuccess') {
          this.showSnackBar('messages.cancelSuccess')
        }
      },
      totalEmployees (value) {
        this.totalEmployee = value
      },
      totalJobsPartime (value) {
        this.totalJobsPartimer = value
      },
      totalCustomers (value) {
        this.totalCustomer = value
      },
      totalJobs (value) {
        this.totalJob = value
      },
      requestBankList (value) {
        if (value.count > 0) {
          this.requestDialog = true
          this.items = value.rows
        } else {
          this.requestDialog = false
        }
      },
      mailsList (value) {
        this.editedEmail.emails = value.rows
      },
      date (value) {
        if (value) {
          this.getDataDashboad()
        }
      },
    },
    async created () {
      this.$store.dispatch('totalCount/getTotalCountParttimer')
      this.$store.dispatch('totalCount/getTotalCountEmployees')
      this.$store.dispatch('totalCount/getTotalCountCustomers')
      // this.$store.dispatch('total-count/getTotalCountEmployee')
      this.getChartProperties()
      this.$store.dispatch('parttimer/getRequestBank')
      this.$store.dispatch('parttimer/getmailsList')
      if (localStorage.getItem('user')) {
        this.userType = JSON.parse(localStorage.getItem('user')).detail.type
      }
    },
    mounted () {
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })

      extend('email', {
        ...email,
        message: this.$t('validator.email_invalid'),
      })
      this.itemsyear2.push('2021年')
      this.getDataDashboad()
    },
    methods: {
      getDataDashboad () {
        const payload = {
          id: JSON.parse(localStorage.getItem('user')).detail.id,
          date: this.date,
        }
        this.$store.dispatch('employee/getDataDashboadFulltime', payload)
      },
      getChartProperties () {
        this.optionsCustomer = {
          credits: {
            enabled: false,
          },
          chart: {
            height: 400,
          },
          title: {
            text: '注文出勤図',
            align: 'left',
            style: {
              color: '#2d89bf',
              marginBottom: '10px',
            },
          },
          xAxis: {
            categories: this.categories,
            tickColor: 'white',
            labels: {
              style: {
                fontSize: '13px',
              },
            },
          },
          yAxis: {
            title: {
              text: '',
            },
            labels: {
              style: {
                fontSize: '16px',
              },
            },
          },
          series: [
            {
              type: 'column',
              name: '注文',
              data: this.totalPlanneds,
              pointWidth: 10,
              maxPointWidth: 10,
              borderRadius: 4,
              color: '#7fa7f5',
              dataLabels: {
                enabled: false,
                style: {
                  fontSize: 14,
                  color: '#5CA6D2',
                },
                y: 5,
              },
            },
            {
              type: 'line',
              name: '出勤',
              data: this.totalActuals,
              pointWidth: 10,
              maxPointWidth: 10,
              borderRadius: 4,
              color: '#e97e1c',
              dataLabels: {
                enabled: false,
                style: {
                  fontSize: 14,
                  color: '#5CA6D2',
                },
                y: 5,
              },
            },
          ],
        }
      },
      changeSelected (value) {
        this.optionChart = value
        this.getChartProperties()
      },
      changeSelected2 (value) {
        this.optionChart2 = value
        this.getChartProperties()
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      approachReq (value) {
        const payload = {
          id: value.parttimeEmployeeId,
          isApprove: true,
        }
        this.$store.dispatch('parttimer/approachReq', payload)
        setTimeout(() => {
          this.$store.dispatch('parttimer/getRequestBank')
        }, 300)
      },
      cancelReq (value) {
        const payload = {
          id: value.parttimeEmployeeId,
          isApprove: false,
        }
        this.$store.dispatch('parttimer/cancelReq', payload)
        setTimeout(() => {
          this.$store.dispatch('parttimer/getRequestBank')
        }, 300)
      },
      openMailDialog () {
        if (this.mailsList.count > 0) {
          this.editedEmail.emails = this.mailsList.rows
        } else {
          this.editedEmail.emails = [{ name: '', email: '' }]
        }
        this.dialogEmail = true
      },
      addMail () {
        const filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        const found = this.editedEmail.emails.find(item => item.email.length === 0)
        const found2 = this.editedEmail.emails.find(item => !filter.test(item.email))
        if (!found) {
          if (!found2) {
            this.editedEmail.emails.push({ name: '', email: '' })
          }
        }
      },
      removeMail (item, index) {
        const itemRemove = this.editedEmail.emails.splice(index, 1)
        if (item.id) {
          itemRemove[0].deletedAt = moment.utc().format()
          this.mailsRemoved.push(itemRemove[0])
        }
        this.dialogDelete = false
      },
      updateEmail () {
        const payload = this.mailsRemoved.length > 0 ? [...this.editedEmail.emails, ...this.mailsRemoved] : [...this.editedEmail.emails]
        this.$store.dispatch('parttimer/updateMailsList', payload)
        this.dialogEmail = false
      },
      closeDialogMail () {
        this.editedEmail.emails = this.mailsList.rows
        this.dialogEmail = false
      },
      handleExportFile () {
        const ws = XLSX.utils.aoa_to_sheet(this.ws_data)
        const ws2 = XLSX.utils.aoa_to_sheet(this.ws_data2)

        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'SheetJS1')
        XLSX.utils.book_append_sheet(wb, ws2, 'SheetJS2')
        XLSX.writeFile(wb, 'sheetjs.xlsx')
      },
      getTotalActual (data) {
        let totalActualFlowDate = []
        data.forEach(workTimeForDateActual => {
          if (totalActualFlowDate.length === 0) {
            totalActualFlowDate = workTimeForDateActual
          } else {
            workTimeForDateActual.forEach((item, index) => {
              totalActualFlowDate[index] = totalActualFlowDate[index] + item
            })
          }
        })

        return totalActualFlowDate
      },
      getTotal (datas) {
        let total = 0
        datas.forEach(data => {
          total += data
        })
        return total / 30
      },
    },
  }
</script>
<style lang="sass" scoped>
  .custom-filter-dashboad
    position: absolute
    top: 5px
    right: 0
    z-index: 2
  .staticals
    background: white
    border-radius: 20px
    .v-chip
      width: 100%
      .v-icon
        position: absolute
        right: 15%
</style>
